import '@assets/scss/pages/project.scss';

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// components
import Layout from '@src/layouts';
import Video from '@components/atoms/Video';
// import Blockquote from '@components/molecules/Blockquote';
// import ButtonExternal from '@components/atoms/buttons/ButtonExternal';
import ButtonSecondary from '@components/atoms/buttons/ButtonSecondary';
import Column from '@components/organisms/Column';
// import Cta from '@components/molecules/Cta';
import Gallery from '@components/organisms/Gallery';
import HeroProject from '@components/molecules/heros/HeroProject';
import Image from '@components/atoms/Image';
// import ItemArticle from '@components/molecules/items/ItemArticle';
import ListText from '@components/organisms/ListText';
import LinkPage from '@components/atoms/LinkPage';

// --------------------------------
// #region data
// --------------------------------

const language = 'en';

const pageClass = 'page-project';

const accent_color = '#3CD5AF';
const footerRelatedLinks = [
	{
		title: 'Théâtre de Vidy-Lausanne',
		url: '/en/projects/theatre-de-vidy-lausanne/',
	},
];

// --------------------------------
// #endregion
// --------------------------------

const ProjectAckTypoPage = (props) => {
	const data = useStaticQuery(
		graphql`
			query {
				heroImage: file(
					relativePath: {
						eq: "projects/ackermann/typography/img-typo-cover.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				heroImageMobile: file(
					relativePath: {
						eq: "projects/ackermann/typography/img-typo-cover-mobile.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_1: file(
					relativePath: {
						eq: "projects/ackermann/typography/img-typo-Medium.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1000, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_2: file(
					relativePath: {
						eq: "projects/ackermann/typography/img-typo-bold.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_3: file(
					relativePath: {
						eq: "projects/ackermann/typography/img-typo-caracteristiques-EN.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_4: file(
					relativePath: {
						eq: "projects/ackermann/typography/img-typo-affiche-01.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_5: file(
					relativePath: {
						eq: "projects/ackermann/typography/img-typo-affiche-02.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_6: file(
					relativePath: {
						eq: "projects/ackermann/typography/img-typo-application-01.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_7: file(
					relativePath: {
						eq: "projects/ackermann/typography/img-typo-application-02.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_8: file(
					relativePath: {
						eq: "projects/ackermann/typography/img-typo-application-03.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_9: file(
					relativePath: {
						eq: "projects/ackermann/typography/img-typo-application-04.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_10: file(
					relativePath: {
						eq: "projects/ackermann/typography/img-typo-application-05.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_11: file(
					relativePath: {
						eq: "projects/ackermann/typography/img-link-brand.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	);

	return (
		<Layout
			language={language}
			pageClass={pageClass}
			footerRelatedLinks={footerRelatedLinks}
			accentColor={accent_color}
			location={props.location}
			translations={[
				{
					uri: '/fr/projets/ackermann-typographie/',
					lang: 'fr',
				},
			]}
			title="Ackermann Typography - By Superhuit"
		>
			<HeroProject
				breadcrumb={{
					title: 'Work',
					link: '/en/projects/',
				}}
				title="Ackermann typography"
				image={{
					...data.heroImage.childImageSharp,
				}}
				imageMobile={{
					...data.heroImageMobile.childImageSharp,
				}}
			/>
			<section className="grid grid-content" aria-label="Project">
				<div className="project-intro" data-animation-page>
					<div className="project-summary text-content">
						<p className="p-medium">
							The typography designed for Ackermann highlights the
							strengths of what sets the brand apart as unique.
						</p>

						<ListText
							className="element-spacing"
							title="Our services"
							list={['Typography', 'Brand positioning']}
						/>
					</div>
					<div className="project-description text-content">
						<h3>The need for consistency</h3>
						<p>
							When redesigning{' '}
							<LinkPage href="/en/projects/ackermann-branding">
								Ackermann's visual identity
							</LinkPage>
							, consistency was a key consideration. Just like
							good bread requires local and quality ingredients, a
							strong visual identity requires attention to detail
							and consistency.
						</p>
						<p>
							To meet this challenge, we designed a custom
							typography that creates a strong link to the
							symbolism of the logo and increases the visual
							richness.
						</p>

						<h3>Related subjects</h3>
						<ButtonSecondary
							href="/en/projects/ackermann-branding/"
							text="Ackermann rebranding"
							data-target
						/>
					</div>
				</div>

				<Column modifiers="right">
					<div className="column__text column__sticky text-content">
						<h3>Between tradition and progression</h3>
						<p>
							By blending the clean structure of contemporary
							lineal letters with the contrast of Roman capitals,
							the typography offers a perfect graphic balance
							between traditional and contemporary styles as a nod
							to the Ackermann DNA.
						</p>
						<p>
							The rhythm, which offers a stark contrast between
							the verticals and the horizontals, creates a strong
							and dynamic personality.
						</p>
					</div>

					<div className="column__media text-content">
						<Image
							{...data.image_1.childImageSharp}
							alt="Typography medium"
						/>
						<Image
							{...data.image_2.childImageSharp}
							alt="Typography bold"
						/>
					</div>
				</Column>

				<Column modifiers="left">
					<div className="column__media text-content">
						<Video src="5213937a7a2b9ddff0b1601bf489ec4c" alt="" />
						<Video src="e19f3a25549d8cf6c2009402a42b2267" alt="" />
						<Image
							{...data.image_3.childImageSharp}
							alt="Characteristics"
						/>
					</div>

					<div className="column__text column__sticky text-content">
						<h3>A task of readability</h3>
						<p>
							Based on the lettering design of the Ackermann logo,
							the letters have been reworked. The aim is to offer
							the best possible readability while also creating a
							slight distinction to the logo’s typography.
						</p>
						<p>
							On the barrels, the mark of the concave forms were
							inspired by engravings on stone. This feature boosts
							the human and authentic sense of the brand.
						</p>
					</div>
				</Column>

				<Gallery className="col-full grid-spacing" modifiers="gutter">
					<Image {...data.image_4.childImageSharp} alt="Poster" />
					<Image {...data.image_5.childImageSharp} alt="Poster" />
				</Gallery>

				<Image
					className="col-full"
					{...data.image_6.childImageSharp}
					alt="Application"
				/>

				<Image
					className="col-full grid-spacing"
					{...data.image_7.childImageSharp}
					alt="Application"
				/>

				<Gallery className="col-full grid-spacing" modifiers="gutter">
					<Image
						{...data.image_8.childImageSharp}
						alt="Application"
					/>
					<Image
						{...data.image_9.childImageSharp}
						alt="Application"
					/>
				</Gallery>

				<Image
					className="col-full grid-spacing"
					{...data.image_10.childImageSharp}
					alt="Application"
				/>

				<Column modifiers="right">
					<div className="column__text column__sticky text-content">
						<h3>Discover more about the Ackermann project</h3>
						<p>
							Discover the visual identity and the interactive
							site that brings the Ackermann typography to life.
						</p>
						<ButtonSecondary
							href="/en/projects/ackermann-branding/"
							text="See the branding"
							data-target
						/>
					</div>

					<div className="column__media text-content">
						<Image
							{...data.image_11.childImageSharp}
							alt="Branding"
						/>
					</div>
				</Column>
			</section>
		</Layout>
	);
};

export default ProjectAckTypoPage;
